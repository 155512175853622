import { render, staticRenderFns } from "./Pill.vue?vue&type=template&id=58317660&"
import script from "./Pill.vue?vue&type=script&lang=js&"
export * from "./Pill.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/jasontaylor/Sites/Cancom_Secure_Portal_2021/cancom-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('58317660')) {
      api.createRecord('58317660', component.options)
    } else {
      api.reload('58317660', component.options)
    }
    module.hot.accept("./Pill.vue?vue&type=template&id=58317660&", function () {
      api.rerender('58317660', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/components/pill/Pill.vue"
export default component.exports